import React from 'react';
import { useState ,useMemo} from "react";
import Swal from "sweetalert2";
import {Row,Col,Button} from "reactstrap";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import QuestionIcon from '../../components/QuestionIcon';
import ReactTablePopUpMultiSelect from '../../components/ReactTablePopUpMultiSelect';
import { useCookies } from 'react-cookie';
import TableRowsSalesPostingEdit from "./TableRowsSalesPostingEdit";
import {displayErrorToast,displayError} from '../../helpers';
import { useLocation, Link } from 'react-router-dom';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { getUserAllowedPlants} from '../../services/PlantServices';
import { getAllCustomerMain,getCustomerMainDetails} from '../../services/CustomerMainServices';
import {getAllCustomerSites,getCustomerSiteDetails} from '../../services/CustomerSiteServices';
import { getSalesOrdersForInvoice } from '../../services/SalesOrderServices';
import { getDeliveryChallansForInvoice,getAllDeliveryChallans } from '../../services/DeliveryChallanServices';
import { getInvoiceDetails,updateSalesInvoice,deleteSalesInvoice,cancelSalesInvoice} from '../../services/SalesInvoiceServices';
import { showBillingPop, showCustomerSitePop} from './SalesPostingUtils';
import SummaryIcon from '../../components/SummaryIcon';
import { getDisplayDate } from '../../utils/DateUtils';
import ViewOnlyTextbox from '../../components/ViewOnlyTextbox';
import NumberSetting from '../../components/NumberSetting';
import { useParams } from 'react-router-dom';
import { getSalesOrderDetails } from '../../services/SalesOrderServices';

function SalesInvoiceEdit() {
  const {id}=useParams()
  const [showModal, setShowModal] = useState(false);
  const [showModalDc, setShowModalDc] = useState(false);
  const initial_table_data = {
      records: [],
  };
  const location = useLocation(); 
  const [data, setData] = useState(initial_table_data);
  const [cookies] = useCookies(['myToken']);
  const [Dcrows, setDcrows] = useState([]);
  const [dcList, setDcList] = useState([]);
  const [plants, setPlants] = React.useState([]);
  const [detailList,setDetailList] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [sites, setSites] = React.useState([]);
  const [allSalesOrders,setAllSalesOrders] = useState([]);
  const [allDeliveryChallans,setAllDeliveryChallans] = useState([]);
  const [selectedSalesOrder,setSelectedSalesOrder] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [inputs, setInputs] = useState({});
  const [isLoading, setIsLoading] = useState(true);  
  const [checkedDcIds, setCheckedDcIds] = useState({});
  const navigate = useNavigate();

  React.useEffect(() => {
    if (id)
   {
  setInProgress(true);
  getInvoiceDetails(cookies, id)
  .then(response => {
      setInProgress(false);
      const EMPTY_STRING = '';
      setInputs({
          id:response.id,
          invoice_no: response.invoice_no,
          is_einvoice_needed:false,
          invoice_num_prefix: response.prefix + response.invoice_no,
          prefix:response.prefix ,
          plant_id:response.plant.id,
          invoice_date:(response.invoice_date? getDisplayDate(response.invoice_date) :EMPTY_STRING),
          invoice_time:response.invoice_time,
          remarks:response.user_remarks,
          sales_order_no: response.sales_order.prefix+response.sales_order.order_no,
          sales_order_date: getDisplayDate(response.sales_order.order_date),
          invoice_qty: response.invoice_qty,
          cgst_amt: response.cgst_amt,
          sgst_amt: response.sgst_amt,
          igst_amt: response.igst_amt,
          gst_amt: response.gst_amt,
          tcs_per: response.tcs_per,
          tcs_amt: response.tcs_amt,
          taxable_amt: response.taxable_amt,
          gross_amt: response.gross_amt,
          rounded_off: response.rounded_off,
          user_remarks: response.user_remarks, 
          invoice_amt:response.invoice_amt,
          is_tcs_applicable:response.sales_order.consignee.customer.is_tcs_applicable,
      });
      getSalesOrderDetails(cookies,response.sales_order.id)
      .then(salesorder =>{
        setSelectedSalesOrder(salesorder)
        setInputs(values => ({...values,customer_id:salesorder.consignee.customer.id,
          consignee_id:salesorder.consignee.id
        })) 
    })
      getAllDeliveryChallans(cookies,response.plant.id)
      .then(deliverychallanList => {
        setAllDeliveryChallans(deliverychallanList.delivery_challan_list)
      });
    const so_rows = response.detail_list.map((invoice_detail,index) => {
      const totalDcQty = invoice_detail.dc_list.reduce((sum, dc) => sum + dc.dc_qty, 0);
    return {
        id: invoice_detail.id,
        sl_no: index+1,
        isChecked:true,
        product: invoice_detail.sales_order_detail.product.name,
        unit: invoice_detail.sales_order_detail.product.unit.symbol,
        so_dtl_id:invoice_detail.sales_order_detail.id,
        dc_qty: totalDcQty,
        accepted_qty:invoice_detail.invoice_qty,
        delivery_mode:invoice_detail.sales_order_detail.delivery_mode.toUpperCase(),
        tax:invoice_detail.sales_order_detail.tax.name,
        taxable_amt:invoice_detail.taxable_amt,
        basic_rate:invoice_detail.sales_order_detail.basic_rate,
        cgst_amt:invoice_detail.cgst_amt,
        sgst_amt:invoice_detail.sgst_amt,
        igst_amt:invoice_detail.igst_amt,
        gst_amt:parseFloat(invoice_detail.gst_amt),
        gross_amt:(parseFloat(invoice_detail.cgst_amt)+parseFloat(invoice_detail.sgst_amt)+parseFloat(invoice_detail.igst_amt)+parseFloat(invoice_detail.taxable_amt)).toFixed(2)
  }})
  setRowsData(so_rows)
  setDetailList(response.detail_list)
  setCheckedDcIds(
    response.detail_list.reduce((acc, detail) => {
      acc[detail.sales_order_detail.id] = detail.dc.split(",").map(Number); // Assuming `detail.dc` is a single value, not an array.
      return acc;
    }, {})
  );
    })
    .catch(error => {
      // console.log(error+"error")
        setInProgress(false);
        // displayError(error.response.data, "Loading Failed");
      });
  }
  }, []);

  React.useEffect(() => {
    //Load data for Plant dropdown
    getUserAllowedPlants(cookies)
    .then (
      plantList => {
        const plants = plantList.plant_list.filter(obj => obj.status).map(
            plant => {
                return { value: plant.id, label: plant.plant_alias }
            }
        );
        setPlants(plants);
      }
    )
  }, []);

  React.useEffect(() => {
    getAllCustomerMain(cookies)
    .then (
        customerList => {
          const customers = customerList.customer_list.filter(obj => obj.status).map(
              customer => {
                  return { value: customer.id, label: customer.name }
              }
          );
          setCustomers(customers);
        }
    )
  }, []);

  React.useEffect(() => {
  getAllCustomerSites(cookies)
  .then (
      response => {
        const sites = response.consignee_list.filter(obj => obj.status).map(
            site => {
                return { value: site.id, label: site.name }
            }
        );
        setSites(sites);
      }
  )
  }, []);

  const handleInvoiceDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if(name === "creation_date"){
        setInputs(values => ({...values, 'invoice_date': event.target.value}))
    } 
    else {
        setInputs(values => ({...values, [name]: event.target.value}))
    }
  }

  const showCustomerDetails = () => {
    if (selectedSalesOrder.id) 
    {
      getCustomerMainDetails(cookies,selectedSalesOrder.consignee.customer.id)
      .then(selectedCustomer => {
          showBillingPop(selectedCustomer)
      })
    }
  }

  const showSiteDetails = () => {
    if (selectedSalesOrder.id) 
    {
      getCustomerSiteDetails(cookies,selectedSalesOrder.consignee.id)
      .then(consignee => {
        showCustomerSitePop(consignee);
      })
    }
  }

  const [checkedSoIds, setCheckedSoIds] = useState([]);
  const handleChangeSalesOrderDetails = (value, event) => {
    // console.log(value,"value",event.target.checked,"event.target.checked")
    const isChecked = event.target.checked;
    if (isChecked) 
    {
        setCheckedSoIds(prevIds => [...prevIds, value]);
    } 
    else {
        setCheckedSoIds(prevIds => prevIds.filter(item => item !== value));
    }
    // Update the data to reflect the checkbox state
    setData(prevData => ({
      ...prevData,
      records: prevData.records.map(record => 
          record.id === value ? { ...record, isChecked } : record
      )
    }));
  } 

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    try
    {
      setInputs(values => ({ ...values, [name]: event.target.value }))
    }
    catch(e)
    {
      displayErrorToast(e);
    } 
  }
  
  const showDcdetails = (index,evnt) =>{
    const { name, value } = evnt.target;
    // console.log(name+"name"+value+"value");
    const rowsInput = [...rowsData];
    console.log(JSON.stringify(rowsInput)+"rowsInput")
    rowsInput[index][name] = value;
    console.log(rowsInput[index]['id']+ "rowsInput[index]['id']"+ rowsInput[index]['so_dtl_id'])

    console.log(JSON.stringify(dcList)+"dcList on product click");
    console.log(JSON.stringify(checkedDcIds)+"checkedDcIds on product click");
    console.log(JSON.stringify(detailList)+"detailList on product click");

    if (rowsInput[index]['id']){
    const filteredDetail = detailList.filter(detail => detail.sales_order_detail.id === rowsInput[index]['so_dtl_id']);
    console.log(JSON.stringify(filteredDetail)+"filteredDetail");
    const dcList = filteredDetail ? filteredDetail[0].dc_list : [];
    console.log(JSON.stringify(dcList)+"dcList");
    setDcrows(dcList.map(dc => ({
      vehicle_no:dc.customer_vehicle_no?dc.customer_vehicle_no:dc.vehicle.name,
      id:dc.id,
      dc_qty:dc.dc_qty,
      accepted_qty:dc.accepted_qty,
      customer_status:dc.dc_status?dc.dc_status.toUpperCase():"WAITING FOR CHECKIN",
      customer_dc_status:dc.customer_dc_status?dc.customer_dc_status.toUpperCase():"WAITING FOR CHECKIN",
      delivery_challan_no:dc.prefix+dc.delivery_challan_no,
      delivery_challan_date:dc.delivery_challan_date,
      checkout_no:dc.checkout_no,
      isChecked:true,
      isDisabled:true
      // dc.accepted_qty>0?false:true,
      // 
    })))
    }
   else{
    setDcrows(dcList.filter(dc=>dc.work_schedule.sales_order_detail.id == rowsInput[index]['so_dtl_id']).map(dc => ({
      vehicle_no:dc.customer_vehicle_no?dc.customer_vehicle_no:dc.vehicle.name,
      id:dc.id,
      dc_qty:dc.dc_qty,
      accepted_qty:dc.accepted_qty,
      customer_status:dc.customer_status?dc.customer_status.toUpperCase():"WAITING FOR CHECKIN",
      customer_dc_status:dc.customer_dc_status?dc.customer_dc_status.toUpperCase():"WAITING FOR CHECKIN",
      delivery_challan_no:dc.prefix+dc.delivery_challan_no,
      delivery_challan_date:dc.delivery_challan_date,
      checkout_no:dc.checkout_no,
      isChecked:dc.isChecked,
      isDisabled:dc.accepted_qty>0?false:true,
      so_dtl_id:dc.work_schedule.sales_order_detail.id
    })))
   }
    setShowModalDc(true);
    setIsLoading(false);
  }

  const [rowsData, setRowsData] = useState([]);

  const onDeleteSalesInvoice = (event) => {
    event.preventDefault();
    Swal.fire({title: 'Are you sure to Delete?',  
    showCancelButton: true,  
    confirmButtonText: `Yes`,
    cancelButtonText: `No`,
    }).then((result) => {  
      if (result.isConfirmed) { 
        setIsLoading(true);
        deleteSalesInvoice(cookies, id)
        .then(response => {
            console.log(`Invoice with id ${id} deleted`);
            Swal.fire('Deleted Successfully!', '', 'success');
            view();
        })
        .catch(error => {
            console.log(error.response.data);
            displayError(error.response.data,'Delete Failed');
        }); 
      }
      else if (result.isDismissed) {    
        Swal.fire('Not Deleted', '', 'info')  
      }
    });
  };

  const handleSubmit = (event) => {
      event.preventDefault();
      // console.log(JSON.stringify(checkedDcIds)+"checkedDcIds");
      // console.log(JSON.stringify(rowsData)+"rowsData");
      // console.log(JSON.stringify(dcList)+"dclist")
      if((inputs.invoice_amt > 0) && (rowsData.length>0)){
        try{
            Swal.fire({
              title: 'Do you want to Update?',
              showCancelButton: true,
              cancelButtonText: `No`,
              showDenyButton: true, // Add a third button
              confirmButtonText: `Update only`,
              denyButtonText: `Update and Printout`, // Text for the third button
            }).then((result) => {
              if ((result.isConfirmed) || (result.isDenied)) {
                const EMPTY_STRING = '';
                    updateSalesInvoice(cookies, {
                            "update_only":result.isConfirmed?true:false,
                            "id":parseInt(inputs.id),
                            "plant_id": parseInt(inputs.plant_id),
                            "invoice_no": inputs.invoice_no,
                            "invoice_date": inputs.invoice_date ? getDisplayDate(inputs.invoice_date) : EMPTY_STRING,
                            "invoice_time":inputs.invoice_time,
                            "prefix": inputs.prefix,
                            "invoice_qty": rowsData.reduce((sum, row) => {
                              return (
                                sum + parseFloat(row.accepted_qty)
                              )
                            }, 0),
                            "cgst_amt": parseFloat(inputs.cgst_amt),
                            "sgst_amt": parseFloat(inputs.sgst_amt),
                            "igst_amt": parseFloat(inputs.igst_amt),
                            "gst_amt":parseFloat(inputs.gst_amt),
                            "gross_amt": parseFloat(inputs.gross_amt),
                            "rounded_off":parseFloat(inputs.rounded_off),
                            "invoice_amt": parseFloat(inputs.invoice_amt),
                            "tcs_amt": parseFloat(inputs.tcs_amt),
                            "taxable_amt": parseFloat(inputs.taxable_amt),
                            "user_remarks": inputs.remarks?inputs.remarks:EMPTY_STRING,
                            "detail_list": rowsData.map(so => {
                              const dc_ids_list = checkedDcIds[so.so_dtl_id]
                              // dcList.filter(dc => dc.work_schedule.sales_order_detail.id === so.id)
                              //     .map(dc => dc.id)
                              //     .filter(id => checkedDcIds.includes(id));
                              return {
                                  "id":so.id,
                                  "sales_order_detail_id": parseInt(so.so_dtl_id),
                                  "invoice_qty": parseFloat(so.accepted_qty),
                                  "accepted_qty": parseFloat(so.accepted_qty),
                                  "dc_ids_list": dc_ids_list
                              };
                          })
                          // "detail_list":detailList.map(detail => ({
                          //   "id":detail.id,
                          //   "sales_order_detail_id": parseInt(detail.sales_order_detail.id),
                          //   "invoice_qty": parseFloat(detail.invoice_qty),
                          //   "accepted_qty": parseFloat(detail.invoice_qty),
                          //   "dc_ids_list": detail.dc_list.map(dc=> dc.id)})
                          // )
                    },id)
                    .then(response => {
                      console.log(JSON.stringify(response)+"response");
                      if (result.isConfirmed){
                        Swal.fire("Updated!", "", "success");
                      }
                      if (result.isDenied){
                        const file = new Blob([response], { type: "application/pdf" });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        // Open the URL in a new window or tab
                        window.open(fileURL);
                      }
                        view();
                    })
                    .catch((error) =>
                    {
                        console.log(error+"error submit");
                        displayError(error.response.data,"Update Failed");
                    })
              }
              else if (result.isDismissed) {
                Swal.fire('Not Updated', '', 'info')
              }
            });
        }
        catch(e)
        {
          displayErrorToast(e);
        }
      }
      else if (!(inputs.invoice_amt > 0 )){
        Swal.fire('Invoice amount must be greater than zero.', '', 'info')
      }
  }

  const Reload = () => {
    window.location.reload();
  }     
    
  const ShowOrderDetails = () => {
    if (inputs.plant_id)
    {
      setCheckedSoIds([])
      setRowsData([])
      // console.log(JSON.stringify(inputs)+"inputs");
      getSalesOrdersForInvoice(cookies,inputs.plant_id,inputs.id)
      .then (sales_order_list => {
        setAllSalesOrders(sales_order_list.sales_order_list)
        const tableData = sales_order_list.sales_order_list
        .map(so_dtl=> ({
            id :so_dtl.id,
            order_no:so_dtl.sales_order.prefix+so_dtl.sales_order.order_no,  
            order_date: so_dtl.sales_order.order_date,                             
            customer:so_dtl.sales_order.consignee.customer.name, 
            company:so_dtl.sales_order.company.name,
            project_name:so_dtl.sales_order.consignee.project_name,
            grade:so_dtl.product.name,
            delivery_mode:so_dtl.delivery_mode,
            total_dc_qty:so_dtl.total_dc_qty,
            total_accepted_qty:so_dtl.total_accepted_qty,
            so_rate:so_dtl.rate,
            isChecked:false,
            isDisabled:false
        }));
        setData({
            records: tableData
        });
        setIsLoading(false);
        setShowModal(true)
      })
    }
    else{
      Swal.fire("Please choose Plant.", '', 'info')
    }
  }

  const Close = () => {
    // console.log(JSON.stringify(checkedSoIds)+"checkedSoIds Close)");
    setShowModal(false)
    if ((inputs.plant_id) && (checkedSoIds.length>0)){
      let tcs_per = 0; // Initialize tcs_per in the outer scope
      getDeliveryChallansForInvoice(cookies,inputs.plant_id,checkedSoIds.join(','),inputs.invoice_date,inputs.id)
      .then(response => {
        setIsLoading(false);
        setDcList(response.delivery_challan_list)
        response.delivery_challan_list.map(dc => {
          setInputs(values => ({...values,
            sales_order_id: dc.work_schedule.sales_order_detail.sales_order.id,
            company_id:dc.work_schedule.sales_order_detail.sales_order.company.id,
            customer_id:dc.work_schedule.sales_order_detail.sales_order.consignee.customer.id,
            consignee_id:dc.work_schedule.sales_order_detail.sales_order.consignee.id,
            sales_order_no: dc.work_schedule.sales_order_detail.sales_order.prefix+dc.work_schedule.sales_order_detail.sales_order.order_no,
            sales_order_date: getDisplayDate(dc.work_schedule.sales_order_detail.sales_order.order_date),
            is_tcs_applicable:dc.work_schedule.sales_order_detail.sales_order.consignee.customer.is_tcs_applicable,
            tcs_per:dc.work_schedule.sales_order_detail.sales_order.consignee.customer.tcs_per
          }))
          setSelectedSalesOrder(dc.work_schedule.sales_order_detail.sales_order);
          if(dc.accepted_qty > 0){
            setCheckedDcIds(values => {
              const updatedCheckedDcIds = { ...values };
                  // If dtl_id exists, add the dc ID to the array, otherwise create a new array with this dc ID
                  if (updatedCheckedDcIds[dc.work_schedule.sales_order_detail.id]) {
                    if (!updatedCheckedDcIds[dc.work_schedule.sales_order_detail.id].includes(dc.id)) {
                      updatedCheckedDcIds[dc.work_schedule.sales_order_detail.id] = [...updatedCheckedDcIds[dc.work_schedule.sales_order_detail.id], dc.id];
                    }
                    } else {
                      updatedCheckedDcIds[dc.work_schedule.sales_order_detail.id] = [dc.id];
                  }
                  return updatedCheckedDcIds;})
          }
           tcs_per = dc.work_schedule.sales_order_detail.sales_order.consignee.customer.tcs_per;
          setDcList(prevData => (
            prevData.map(record => 
                record.id === dc.id ? { ...record, isChecked:dc.accepted_qty>0?true:false} : record
            )
          ));
        })
        const filteredRows = allSalesOrders.filter(order => checkedSoIds.includes(order.id)).map(order => ({
          id:'',
          so_dtl_id: order.id,
          isChecked:true,
          product: order.product.name,
          unit: order.product.unit.symbol,
          dc_qty: order.total_dc_qty,
          accepted_qty:order.total_accepted_qty,
          delivery_mode:order.delivery_mode.toUpperCase(),
          tax:order.tax.name,
          taxable_amt:order.taxable_amt,
          basic_rate:order.basic_rate,
          cgst_amt:order.cgst_amt,
          sgst_amt:order.sgst_amt,
          igst_amt:order.igst_amt,
          cgst_per:order.cgst_per,
          sgst_per:order.sgst_per,
          igst_per:order.igst_per,
          gst_amt:(parseFloat(order.cgst_amt)+parseFloat(order.sgst_amt)+parseFloat(order.igst_amt)).toFixed(2),
          gross_amt:(parseFloat(order.cgst_amt)+parseFloat(order.sgst_amt)+parseFloat(order.igst_amt)+parseFloat(order.taxable_amt)).toFixed(2)
        }))
        setRowsData(filteredRows);
        const totalAmounts = filteredRows.reduce((totals, row) => {
          return {
            taxable_amt: totals.taxable_amt + parseFloat(row.taxable_amt),
            cgst_amt: totals.cgst_amt + parseFloat(row.cgst_amt),
            sgst_amt: totals.sgst_amt + parseFloat(row.sgst_amt),
            igst_amt: totals.igst_amt + parseFloat(row.igst_amt),
            gst_amt: totals.gst_amt + parseFloat(row.gst_amt),
            gross_amt: totals.gross_amt + parseFloat(row.gross_amt)
          };
        }, { taxable_amt: 0, cgst_amt: 0, sgst_amt: 0, igst_amt: 0 ,gst_amt:0,gross_amt:0});
        // console.log(tcs_per+"tcs_per close")
        var tcs_amt = parseFloat(tcs_per/100)*parseFloat(totalAmounts.taxable_amt + totalAmounts.gst_amt)
        var gross_amt=(parseFloat(totalAmounts.taxable_amt)+ parseFloat(totalAmounts.gst_amt)+parseFloat(tcs_amt))
        // console.log(tcs_amt+"tcs_amt close")
        // Update the state with the calculated sums
        setInputs(prevInputs => ({
          ...prevInputs,
          taxable_amt: totalAmounts.taxable_amt.toFixed(2),
          cgst_amt: totalAmounts.cgst_amt.toFixed(2),
          sgst_amt: totalAmounts.sgst_amt.toFixed(2),
          igst_amt: totalAmounts.igst_amt.toFixed(2),
          gst_amt: totalAmounts.gst_amt.toFixed(2),
          tcs_amt:tcs_amt.toFixed(2),
          gross_amt: gross_amt.toFixed(2),
          invoice_amt:Math.ceil(gross_amt).toFixed(2),
          // Math.round(gross_amt).toFixed(2),
          rounded_off:(Math.ceil(gross_amt)-(gross_amt)).toFixed(2)
        }));
        // console.log(Math.round(2128.5).toFixed(2)+"Math.round(gross_amt).toFixed(2)")
      })
      .catch((error) =>
      {
        setIsLoading(false);
        // console.log(error.response.data);
        displayError(error.response.data," Failed");
      })
        // console.log(JSON.stringify(allSalesOrders)+"allSalesOrders Close")
        // console.log(JSON.stringify(inputs)+"inputs close")
    }
  }

  const CloseDc = () => {
    console.log(JSON.stringify(Dcrows)+"Dcrows CloseDc");
    console.log(JSON.stringify(rowsData)+"rowsData CloseDc");
    const isChecked = Dcrows.some(row => row.isChecked);
    if (!isChecked) {
      const so_dtl_id = Dcrows.reduce((so_id, item) => {
        return item.so_dtl_id;
      }, 0);
      console.log(so_dtl_id+"so_dtl_id CloseDc");
      if (rowsData.length == 1){
        setRowsData([])
        setInputs(prevInputs => ({
          ...prevInputs,
          taxable_amt: 0,
          cgst_amt: 0,
          sgst_amt: 0,
          igst_amt: 0,
          gst_amt: 0,
          gross_amt: 0,
          invoice_amt:0,
          rounded_off:0,
          tcs_amt:0
        }));
      }
      else
      {
        const totalAmounts = rowsData.reduce((totals, row) => {
          if(row.id != so_dtl_id){
            console.log("row.id != so_dtl_id CloseDc"+JSON.stringify(row))
          return {
            taxable_amt: totals.taxable_amt + parseFloat(row.taxable_amt),
            cgst_amt: totals.cgst_amt + parseFloat(row.cgst_amt),
            sgst_amt: totals.sgst_amt + parseFloat(row.sgst_amt),
            igst_amt: totals.igst_amt + parseFloat(row.igst_amt),
            gst_amt: totals.gst_amt + parseFloat(row.gst_amt),
            gross_amt: totals.gross_amt + parseFloat(row.gross_amt)
          };
        }return totals;
        }, { taxable_amt: 0, cgst_amt: 0, sgst_amt: 0, igst_amt: 0 ,gst_amt:0,gross_amt:0});
        console.log(JSON.stringify(totalAmounts)+"totalAmounts close Dc")
        var tcs_amt = parseFloat(inputs.tcs_per/100)*parseFloat(totalAmounts.taxable_amt + totalAmounts.gst_amt);
        var gross_amt = (parseFloat(totalAmounts.taxable_amt)+ parseFloat(totalAmounts.gst_amt)+parseFloat(tcs_amt))
        // Update the state with the calculated sums
        setInputs(prevInputs => ({
          ...prevInputs,
          taxable_amt: totalAmounts.taxable_amt.toFixed(2),
          cgst_amt: totalAmounts.cgst_amt.toFixed(2),
          sgst_amt: totalAmounts.sgst_amt.toFixed(2),
          igst_amt: totalAmounts.igst_amt.toFixed(2),
          gst_amt: totalAmounts.gst_amt.toFixed(2),
          gross_amt: gross_amt.toFixed(2),
          tcs_amt:tcs_amt.toFixed(2),
          invoice_amt:Math.ceil(gross_amt).toFixed(2),
          // Math.round(gross_amt).toFixed(2),
          rounded_off:(Math.ceil(gross_amt)-(gross_amt)).toFixed(2)
        }));
        
        setRowsData(prevRows => prevRows.filter(item => item.id !== so_dtl_id));
        setShowModalDc(false)
      }
      // Swal.fire('No dcs are selected.', '', 'info')
    } 
    else{
      // To calculate total accepted qty
      const acceptedQtyMap = Dcrows.reduce((map, item) => {
        if (item.isChecked) {
          // console.log("if");
          const id = item.so_dtl_id;
          map[id] = (map[id] || 0) + item.accepted_qty;
          map['dc_qty'] = (map['dc_qty'] || 0) + item.dc_qty;
        }
        return map;
      }, {});
      console.log(JSON.stringify(acceptedQtyMap)+"acceptedQtyMap CloseDc");
      // Update rowsData based on the acceptedQtyMap
      rowsData.forEach(row => {
        if (acceptedQtyMap.hasOwnProperty(row.so_dtl_id)) {
          console.log(JSON.stringify(row)+"row CloseDc");
          row.accepted_qty = acceptedQtyMap[row.so_dtl_id];
          row.dc_qty = acceptedQtyMap['dc_qty'];
          var taxable_amt = Math.ceil(parseFloat(row.basic_rate)*parseFloat(acceptedQtyMap[row.so_dtl_id])*100)/100;
          // parseFloat(row.basic_rate)*parseFloat(acceptedQtyMap[row.so_dtl_id]);
          // console.log(row.cgst_per+"row.cgst_per")
          var cgst_amt = ((taxable_amt*row.cgst_per)/100).toFixed(2);
          row.taxable_amt=(taxable_amt).toFixed(2)
          row.cgst_amt=cgst_amt
          row.sgst_amt=((taxable_amt*row.sgst_per)/100).toFixed(2)
          row.igst_amt=((taxable_amt*row.igst_per)/100).toFixed(2)
          row.gst_amt=(parseFloat(row.cgst_amt)+parseFloat(row.sgst_amt)+parseFloat(row.igst_amt)).toFixed(2)
          row.gross_amt=(parseFloat(row.gst_amt)+parseFloat(row.taxable_amt)).toFixed(2)
        }
      });

      const totalAmounts = rowsData.reduce((totals, row) => {
        return {
          taxable_amt: totals.taxable_amt + parseFloat(row.taxable_amt),
          cgst_amt: totals.cgst_amt + parseFloat(row.cgst_amt),
          sgst_amt: totals.sgst_amt + parseFloat(row.sgst_amt),
          igst_amt: totals.igst_amt + parseFloat(row.igst_amt),
          gst_amt: totals.gst_amt + parseFloat(row.gst_amt),
          gross_amt: totals.gross_amt + parseFloat(row.gross_amt)
        };
      }, { taxable_amt: 0, cgst_amt: 0, sgst_amt: 0, igst_amt: 0 ,gst_amt:0,gross_amt:0});
      console.log(JSON.stringify(totalAmounts)+"totalAmounts close Dc")
      // Update the state with the calculated sums
      var tcs_amt = parseFloat(inputs.tcs_per/100)*parseFloat(totalAmounts.taxable_amt + totalAmounts.gst_amt)
      var gross_amt = (parseFloat(totalAmounts.taxable_amt)+ parseFloat(totalAmounts.gst_amt)+parseFloat(tcs_amt));
      setInputs(prevInputs => ({
        ...prevInputs,
        taxable_amt: totalAmounts.taxable_amt.toFixed(2),
        cgst_amt: totalAmounts.cgst_amt.toFixed(2),
        sgst_amt: totalAmounts.sgst_amt.toFixed(2),
        igst_amt: totalAmounts.igst_amt.toFixed(2),
        gst_amt: totalAmounts.gst_amt.toFixed(2),
        tcs_amt:tcs_amt.toFixed(2),
        gross_amt:  gross_amt.toFixed(2),
        invoice_amt:Math.ceil(gross_amt).toFixed(2),
        // Math.round(gross_amt).toFixed(2),
        rounded_off:(Math.ceil(gross_amt)-(gross_amt)).toFixed(2)
      }));
      setShowModalDc(false)
    }
  }

  const handleChangeDc = (value, event) => {
    console.log(value,"value",event.target.checked,"event.target.checked handleChangeDc")
    const isChecked = event.target.checked;
    console.log(JSON.stringify(checkedDcIds)+"checkedDcIds handleChangeDc");
    const dtl_id = allDeliveryChallans.filter(item => item.id === value)[0].work_schedule.sales_order_detail.id;
    console.log(dtl_id+"so_dtl_id handleChangeDc");
    setCheckedDcIds(prevIds => {
      // Make a copy of the current checkedDcIds state
      const updatedCheckedDcIds = { ...prevIds };

      if (isChecked) {
          // If dtl_id exists, add the dc ID to the array, otherwise create a new array with this dc ID
          if (updatedCheckedDcIds[dtl_id]) {
              updatedCheckedDcIds[dtl_id] = [...updatedCheckedDcIds[dtl_id], value];
          } else {
              updatedCheckedDcIds[dtl_id] = [value];
          }
      } else {
          // If the dtl_id exists, remove the dc ID from the array
          if (updatedCheckedDcIds[dtl_id]) {
              updatedCheckedDcIds[dtl_id] = updatedCheckedDcIds[dtl_id].filter(id => id !== value);
              // Optionally, remove the dtl_id key if the array is empty
              if (updatedCheckedDcIds[dtl_id].length === 0) {
                  delete updatedCheckedDcIds[dtl_id];
              }
          }
      }
      console.log(JSON.stringify(updatedCheckedDcIds)+"updatedCheckedDcIds handlechangedc")
      // Return the updated state
      return updatedCheckedDcIds;
      
  });
    setDcrows(prevData => (
    prevData.map(record => 
        record.id === value ? { ...record, isChecked } : record
    )
    ));
    setDcList(prevData => (
    prevData.map(record => 
        record.id === value ? { ...record, isChecked} : record
    )
    ));
  }

  const columns =
      useMemo(
          () => [
              {
                  fieldName: "id",
                  headerName: "ROW_ACTIONS",
                  size:40
              },
              {
                  fieldName: 'order_no',
                  headerName: 'Order No',
                  size:200
              },
              {
                  fieldName: 'order_date',
                  headerName: 'Order Date',
                  size:100
              },
              {
                  fieldName: "company",
                  headerName: 'Company',
                  size:180
              },
              {
                  fieldName:"customer",
                  headerName: 'Customer',
                  size:180
              },
              {
                  fieldName:"project_name",
                  headerName: 'Project Name',
                  size:180
              },
              {
                fieldName:"grade",
                headerName: 'Grade',
                size:80
              },
              {
                fieldName:"so_rate",
                headerName: 'Rate',
                size:100
              },
              {
                fieldName:"delivery_mode",
                headerName: 'Delivery Mode',
                size:100
              },
              {
                fieldName:"total_dc_qty",
                headerName: 'Dc Qty',
                size:100
              },
              {
              fieldName:"total_accepted_qty",
              headerName: 'Accepted Qty',
              size:100
              }
          ],
  [],);

  const columnsDc =
      useMemo(
        () => [
          {
            fieldName: "id",
            headerName: "ROW_ACTIONS",
            size:40
          },
          {
            fieldName: 'checkout_no',
            headerName: 'Check out No.',
            size:50
          },
          {
            fieldName: 'delivery_challan_no',
            headerName: 'Dc No',
            size:200
          },
          {
            fieldName: 'vehicle_no',
            headerName: 'Vehicle No.',
            size:180
          },
          {
            fieldName: 'customer_dc_status',
            headerName: 'DC Status',
            size:180
          },
          {
            fieldName: 'customer_status',
            headerName: 'Customer Status',
            size:180
          },
          {
            fieldName: "delivery_challan_date",
            headerName: 'DC Date',
            size:100
          },
          // {
          //   fieldName:"grade",
          //   headerName: 'Grade',
          //   size:100
          // },
          {
            fieldName:"dc_qty",
            headerName: 'Dc Qty',
            size:100
          },
          {
          fieldName:"accepted_qty",
          headerName: 'Accepted Qty',
          size:100
          }
        ],
  [],);

  const Cancel = () => {
      setInputs(() => "")
      setRowsData([])
  }
        
  const view = () => {
    navigate('/SalesInvoiceTable')
  } 
      
  const Back = () => {
    navigate('/Home')
  }

  const updateInvoiceNumberAndPrefix = (prefix, serial_no) => {
    setInputs(values => ({...values,
        ['invoice_num_prefix']:(prefix+serial_no),
        ['invoice_no']:serial_no,
        ['prefix']:prefix}));
  }

  const onCancelSalesInvoice = (event) => {
    event.preventDefault();
     Swal.fire({title: 'Do you want to cancel the Invoice?',  
     showCancelButton: true,  
     confirmButtonText: `Yes`,
     cancelButtonText: `No`,
     }).then((result) => {  
       if (result.isConfirmed) { 
         setIsLoading(true);
         cancelSalesInvoice(cookies, id)
         .then(response => {
             console.log(`Invoice with id ${id} canceled`);
             Swal.fire('Cancelled Successfully!', '', 'success');
             view();
         })
         .catch(error => {
             console.log(error.response.data);
             displayError(error.response.data,'Cancel Failed');
         }); 
          }
       else if (result.isDismissed) {    
         Swal.fire('Not Canceled', '', 'info')  
     }
     });
   };

return (
  <>
    <div id="outer-container"  className="App" > 
      <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
      <ErrorToast/>
      <LoadingOverlay inProgress={inProgress}/>
      
      <div id="page-wrap">
        <form onSubmit={handleSubmit} > 
          <div id="header">
            <h3 className = "text font-weight-bold page-title" >TAX INVOICE EDIT FORM</h3>
          </div>
          
          <FloatingControls tableLink="/SalesInvoiceTable" onCancel={Cancel} enableCancel={false}/>
          
          <div className="container form-control-panel">
            <Row>
              <Col xl={6} lg={12} md={12}>
                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel " >
                  <label htmlFor="plant_id" className="form-group col-sm-5 text-right">Plant Location</label>
                  <select id="plant_id" name="plant_id" className="form-control col-sm-6  browser-default"   value={inputs.plant_id || ""} disabled={true} style={{cursor: "not-allowed" }}>
                    <option value="">Select Plant</option>
                    {plants.map((item) => (
                        <option key={item.value} value={item.value}>
                            {item.label}</option>
                    ))}
                  </select>
                  <br/>
                
                  <label htmlFor="sales_order_no" className="form-group col-sm-5 text-right">Sales order No</label>
                  <input type="text" className="form-control col-sm-6"   id="sales_order_no" value={inputs.sales_order_no || ""} disabled={true} style={{cursor: "not-allowed" }} /><br />
                  <QuestionIcon onClickHandler={ShowOrderDetails}/><br />  
                   
                  <label htmlFor="sales_order_date" className="form-group col-sm-5 text-right">Sales order Date</label>
                  <input type="date" className="form-control col-sm-6"   id="sales_order_date" value={inputs.sales_order_date || ""} style={{cursor: "not-allowed" }} disabled={true}/><br />

                  <label htmlFor="customer_id" className="form-group col-sm-5 text-right">Billing Name</label>
                  <select  id="customer_id" name="customer_id" className="form-control col-sm-6"   value={inputs.customer_id || ""} disabled={true} style={{cursor:"not-allowed"}}>
                    <option value=""></option>
                    {customers.map((item) => (
                            <option key={item.value} value={item.value}>
                            {item.label}
                            </option>
                    ))}
                  </select>
                  <br />
                  <SummaryIcon onClickHandler={showCustomerDetails}/>
                  
                  <label htmlFor="consignee_id" className="form-group col-sm-5 text-right">Ship to</label>
                  <select  id="consignee_id" name="consignee_id" className="form-control col-sm-6"  value={inputs.consignee_id || ""} disabled={true} style={{cursor:"not-allowed"}}>
                    <option value=""></option>
                    {sites.map((item) => (
                            <option key={item.value} value={item.value}>
                            {item.label}
                            </option>
                    ))}
                  </select><br />
                  <SummaryIcon onClickHandler={showSiteDetails}/>
                  <br />
                </div>
              </Col>

              <Col xl={6} lg={12} md={12}>
                <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel ">
                  {/* <NumberSetting 
                      handleDateChange={handleInvoiceDetails}
                      serial_no={inputs.invoice_no} 
                      creation_date={inputs.invoice_date}
                      creation_time={inputs.invoice_time}
                      prefix={inputs.prefix}
                      company_id={inputs.plant_id}
                      voucher_type={"invoice"}
                      handleNumberAndPrefixUpdate={updateInvoiceNumberAndPrefix}
                      cookies={cookies}
                      serial_no_title={'Invoice No'}
                      hideTime={false}
                  /> */}
                  <label htmlFor="invoice_date" className="form-group col-sm-4 text-right">Invoice Date</label>
                  <input type="date" id="invoice_date"  name="invoice_date"  value={inputs.invoice_date || ""} className="form-control col-sm-7" disabled={true} style={{cursor: "not-allowed"}}/>  
                  <label htmlFor="invoice_time" className="form-group col-sm-4 text-right">Invoice Time</label>
                  <input type="time" id="invoice_time"  name="invoice_time"  value={inputs.invoice_time || ""} className="form-control col-sm-7" disabled={true} style={{cursor: "not-allowed"}}/>  
                 
                  <label htmlFor="invoice_num_prefix" className="form-group col-sm-4 text-right">Invoice No</label>
                  <input type="text" id="invoice_num_prefix"  name="invoice_num_prefix"  value={inputs.invoice_num_prefix || ""} className="form-control col-sm-7" disabled={true} style={{cursor: "not-allowed"}}/>  
                  
                  <label htmlFor="is_tcs_applicable" className="form-group col-sm-4 text-right">Is TCS Applicable </label>
                  <select name="is_tcs_applicable" id="is_tcs_applicable"  value={inputs.is_tcs_applicable} className=" form-control col-sm-7 "  disabled={true} style={{cursor: "not-allowed"}} >
                    <option value=""></option> 
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                  <br/>
                  
                  <ViewOnlyTextbox 
                    name="project_name" 
                    value={selectedSalesOrder?.consignee?.project_name|| ""}
                    labelClassName="form-group col-sm-4 text-right"
                    label="Project Name"
                    inputClassName="form-control col-sm-7"
                  /> 
                  
                  <label htmlFor="is_einvoice_needed" className="form-group col-sm-4 text-right">E - invoice</label>
                  <select name="is_einvoice_needed" id="is_einvoice_needed" onChange={handleChange} value={inputs.is_einvoice_needed || false} className="browser-default custom-select form-control col-sm-7 mandatory-form-control" required >
                    <option value="">Please select</option>
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                  <br/>
                   
                  <div className="modal" tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-xl" style={{height: "500px"}} >
                      <div className="modal-content">
                        <div className="modal-body display">
                          <div className="container item-list-table-container">
                            <ReactTablePopUpMultiSelect
                              title='List of Group Form'
                              columns={columns}
                              data={data.records}
                              onRowSubmit={handleChangeSalesOrderDetails}
                              isLoading={isLoading}
                              onClose = {Close}
                            />
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div> 

                  <div className="modal" tabIndex="-1" style={{ display: showModalDc ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-xl" style={{height: "500px"}} >
                      <div className="modal-content">
                        <div className="modal-body display">
                          <div className="container item-list-table-container">
                            <ReactTablePopUpMultiSelect
                              title='List of Group Form'
                              columns={columnsDc}
                              data={Dcrows}
                              onRowSubmit={handleChangeDc}
                              isLoading={isLoading}
                              onClose = {CloseDc}
                            />
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div> 
                </div>
              </Col>
            </Row>

            {rowsData.length>0 && (
              <div className="form-row  shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
                <table className="table-bordered table-responsive searchTable table order-list" id="myTable" style={{textAlign:"center",borderRadius:"10px",backgroundColor:"RGB(188,232,253)",border:'4px solid rgb(235, 73, 73)'}}>
                    <thead>
                      <tr style={{backgroundColor:"RGB(188,232,253)"}}>
                          <th  style={{display:"None"}}>Id</th>
                          <th>Product Code</th>
                          <th>Unit</th>
                          <th>Mode Of Delivery</th>
                          <th>DC Qty</th>
                          <th>Acc/Invoice Qty</th>
                          <th>Basic Rate</th>
                          <th>Taxable Amount</th>
                          <th>GST %</th>
                          <th>CGST Amount</th>
                          <th>SGST Amount</th>
                          <th>IGST Amount</th>
                          <th>GST Amount</th>
                          <th>Gross Amount</th>
                      </tr>
                    </thead>
                    <tbody >
                    <TableRowsSalesPostingEdit rowsData={rowsData} showDcdetails={showDcdetails} />
                    </tbody>
                </table>
              </div> 
            )}
            
            <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
              <table className="table order-list" id="myTable" style={{textAlign:"center",borderRadius:"10px",backgroundColor:"white",border:'4px solid rgb(235, 73, 73)'}}>
                <tbody>
                  <tr >
                    <td colSpan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>Total Taxable Amount </b></td>
                    <td colSpan="4"><input type="text" className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right"}} value={inputs.taxable_amt || 0}></input></td>
                    <td colSpan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>CGST Amount </b></td>
                    <td colSpan="4"><input type="text" className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right" }} value={inputs.cgst_amt || 0}></input></td>
                  </tr>                          
                  <tr>
                    <td colSpan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>Total GST Amount  </b></td>
                    <td colSpan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right" }} value={inputs.gst_amt || 0}></input></td>
                    <td colSpan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b> SGST Amount  </b></td>
                    <td colSpan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right" }} value={inputs.sgst_amt || 0}></input></td>
                  </tr>
                  <tr>
                    <td colSpan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>Total Gross Amount  </b></td>
                    <td colSpan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right" }} value={inputs.gross_amt || 0}></input></td>
                    <td colSpan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>IGST Amount  </b></td>
                    <td colSpan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right" }} value={inputs.igst_amt || 0}></input></td>
                  </tr>
                  <tr>
                    <td colSpan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>Round off  </b></td>
                    <td colSpan="4"><input type="text"  className='form-control'style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right" }} value={inputs.rounded_off || 0}></input></td>
                    <td colSpan="6"style={{textAlign:"right" ,backgroundColor:"white"}}><b>TCS Amount  </b></td>
                    <td colSpan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right" }} value={inputs.tcs_amt || 0}></input></td>
                  </tr>
                  <tr>
                      <td colSpan="14"style={{textAlign:"right" ,backgroundColor:"white"}}><b>Total Invoice Amount  </b></td>
                      <td colSpan="4"><input type="text"  className='form-control' style={{ backgroundColor: "white",cursor: "not-allowed",textAlign:"right" }} value={inputs.invoice_amt || 0}></input></td>
                  </tr>
                </tbody> 
              </table>
            </div>

            <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel ">
                <label htmlFor="remarks" className="form-group col-sm-2 text-right">Remarks</label>
                <textarea rows="2" cols="50" className="form-control col-sm-10" id="remarks" name="remarks" onChange={handleChange} value={inputs.remarks || ""}></textarea>
            </div>
          </div>

          <div className="footer text-center">
            <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Update</Button> 
            <Button type="reset" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Reset</Button>
            <Button className="btn btn-twitter" type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>
            <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
            <Button className="btn btn-delete"  type="button"style={{width:"80px",fontWeight:"bold"}}  onClick={onDeleteSalesInvoice}>Delete</Button>&nbsp;&nbsp;
            <Button className="btn btn-cancel"  type="button"style={{width:"100px",fontWeight:"bold"}} onClick={onCancelSalesInvoice}>Cancel</Button>&nbsp;&nbsp;
          </div>
        </form>
      </div>
    </div>
  </>
);
}
export default SalesInvoiceEdit;